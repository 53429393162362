@import "../../styles/variables";

.resetButton {
  margin-top: 2 * $spacing !important;
  padding: $spacing !important;
  width: 100%;
  background-color: $logistoreBlue !important;
  color: white !important;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: lighten($logistoreBlue, 10%) !important;
  }

  &.hidden {
    opacity: 0;
    height: 0;
    margin-top: 0 !important;
    padding: 0 !important;
  }
}
