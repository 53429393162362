@import "../../styles/variables";

$spinnerSize: 30px;

.fetchingContainer {
  height: 100%;
  width: 100%;
  min-height: $spinnerSize;
  position: relative;

  .spinner {
    position: absolute;
    top: calc(50% - #{$spinnerSize/2});
    left: calc(50% - #{$spinnerSize/2});

    svg {
      color: $logistoreBlue;
    }
  }
}
