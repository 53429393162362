@import "../../styles/variables";

.searchBar {
  background-color: $searchBarBackgroundColor;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  transition: 0.3s;
  color: $darkTextColor;

  .searchIcon {
    margin: 10px;
  }

  .searchInput {
    padding: 0 10px;
    flex-grow: 1;

    input {
      padding: 0;
    }
  }

  &:hover,
  &:focus-within {
    background-color: darken($searchBarBackgroundColor, 5%);
  }
}
