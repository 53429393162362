@import "../../styles/variables.scss";

.photoListItem {
  padding: $spacing;
  cursor: pointer;

  &.active {
    background-color: darken($pageBackgroundColor, 5%);
  }

  &:hover {
    background-color: darken($pageBackgroundColor, 10%);
  }

  img {
    width: 100%;
  }
}
