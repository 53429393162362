@import "../../styles/variables";

.wrapper {
}

.radioButton {
  padding: $spacing 0;
  cursor: pointer;
  user-select: none;
  color: $lightTextColor;

  &.active {
    font-weight: bold;
    color: $darkTextColor;
  }
}
