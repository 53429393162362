@import "../../styles/variables";

.wrapper {
  margin-top: $spacing;
}

.header {
  font-size: 120%;
  font-weight: bold;
  color: $darkTextColor;
  text-transform: uppercase;
}

.content {
  margin: $spacing 0 0 $spacing;
}
