@import "../../styles/variables";

.filterHeading {
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
}

.excelButton {
  padding: $spacing !important;
  font-size: 120%;

  .icon {
    margin-right: $spacing;
  }
}

.items {
  margin: 10px;
  text-align: left;
}
