@import "../../styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  user-select: none;

  &.active {
    color: $darkTextColor;
    font-weight: bold;
  }
}

.label {
  flex-grow: 1;
}

.checkbox {
  padding: 8px !important;
}

.checkbox svg {
  color: darkgray;
}

.checkbox.header.checked svg {
  color: $logistoreBlue;
}

.pointerCursor {
  cursor: pointer;
}
