@import "../../styles/variables";

.img {
  display: block;
  margin: calc(#{$spacing} / 2);
  object-fit: cover;
  width: 60px;
  height: 60px;
}

.bold {
  font-weight: bold !important;
  color: $darkTextColor;
}

.greyText {
  color: darkgray !important;
}

.linkRow {
  cursor: pointer;

  &:hover {
    background: darken($pageBackgroundColor, 2.5%);
  }
}
