@import "../../styles/variables";

.page {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: $pageBackgroundColor;
  display: flex;
  flex-flow: column;
  align-items: stretch;

  .content {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
  }

  .children {
    flex-grow: 1;
    overflow-y: auto;
  }
}
