.container {
  display: flex;
  flexwrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.textField {
  width: 100%;
  marginleft: 28px;
  marginright: 28px;
}
.card {
  width: 354px;
}
.button {
  margin-top: 28px;
  width: 100%;
}
.logo {
  margin-bottom: 28px;
}
.spinner {
  color: white;
}
