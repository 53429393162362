@import "../../styles/variables";

.textFilter {
  background-color: $searchBarBackgroundColor;
  width: 100%;
  text-align: left;
  display: flex;
  transition: 0.3s;

  .input {
    padding: 0 10px;
    flex-grow: 1;
  }

  &:hover,
  &:focus-within {
    background-color: darken($searchBarBackgroundColor, 5%);
  }
}
