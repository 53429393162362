@import "../../styles/variables";

.appBar {
  min-height: $appBarHeight;
  width: 100%;

  display: flex;
  align-items: center;
}

.logoWrapper {
  width: $sidePanelWidth;
  height: $appBarHeight;
  padding: $spacing;
}

.logo {
  object-fit: scale-down;
  height: 100%;
}

.content {
  flex-grow: 1;
  display: flex;
}

.actionButtons {
  margin-left: 2 * $spacing;
}

.logoutButton {
  margin: 0 2*$spacing 0 $spacing !important;
  padding: $spacing !important;
  font-size: 120%;
}
