@import "../../styles/variables";

.row {
  td {
    padding: $spacing 0;
  }
}

.label {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}

.data {
  padding-left: $spacing !important;
}
