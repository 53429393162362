@import "../../styles/variables";

.detailPageLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  max-width: 1500px;
  padding: 3 * $spacing;
}
.photos {
  width: 50%;
}

.itemDetail {
  width: 50%;
  text-align: left;
  padding-left: 3 * $spacing;
}
