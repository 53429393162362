$pageBackgroundColor: rgb(250, 251, 252);
$searchBarBackgroundColor: rgb(246, 247, 248);

$sidePanelWidth: 350px;

$appBarHeight: 80px;

$spacing: 10px;

$logistoreBlue: rgb(47, 148, 240);
$darkTextColor: #343a40;
$lightTextColor: rgb(138, 138, 138);
