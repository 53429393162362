@import "../../styles/variables";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.detailTable {
  table-layout: auto;
  width: auto;
}
