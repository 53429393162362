@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: stretch;
}

.photosList {
  width: 25%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.photoDetail {
  width: 75%;
  padding: $spacing;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}
